module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src4110138966/src/gatsby-apsc/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/Favicon-512x512.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.apexsurgical.ca/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M8MBH7R","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oral Surgeon Kamloops","lang":"en","display":"standalone","short_name":"Oral Surgeon Kamloops","description":"Looking for an oral surgeon in Kamloops? Apex Surgical is a great place for all your oral surgery needs. Dr. Stefanuto is an experienced Oral Surgeon and his staff is a delight to work with. Call for an appointment today.","start_url":"/","background_color":"#eafcfb","theme_color":"#333e48","icon":"src/images/Favicon-512x512.jpg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b9ad025cc270abe3f049dfb9bc32a2ff"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
